<template>
  <div id="projectsWebhooks">
    <b-tabs pills vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-class="nav-left"
      nav-wrapper-class="col-md-3 col-12">
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">Crear webhooks</span>
        </template>
        <projects-webhooks-create :project="project"></projects-webhooks-create>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="MenuIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">Webhooks creados</span>
        </template>
        <projects-webhooks-list :webhooks="webhooks" :project="project"></projects-webhooks-list>
      </b-tab>
      <!--
      <b-tab>
        <template #title>
          <feather-icon
            icon="CalendarIcon"
            size="18"
            class="mr-50"/>
          <span class="font-weight-bold">Historial de webhooks</span>
        </template>
        <projects-webhooks-records :webhooksRecords="webhooksRecords"></projects-webhooks-records>
      </b-tab>
      -->
    </b-tabs>
  </div>
</template>

<script>
  import ProjectsWebhooksCreate from './components/ProjectsWebhooksCreate'
  import ProjectsWebhooksList from './components/ProjectsWebhooksList'
  import ProjectsWebhooksRecords from './components/ProjectsWebhooksRecords'
  import {mapActions, mapState} from 'vuex';

  export default{
    name: 'projectsWebhooks',
    components: {ProjectsWebhooksCreate, ProjectsWebhooksList, ProjectsWebhooksRecords},
    props: ['project'],
    data() {
        return {}
    },
    beforeMount() {
        this._fetchWebhooks()
        this._fetchWebhooksRecords()
    },
    computed: {
        ...mapState('projects',['webhooks', 'webhooksRecords']),
    },
    methods: {
        ...mapActions('projects',['fetchWebhooks', 'fetchWebhooksRecords']),
        async _fetchWebhooks() {
            await this.fetchWebhooks(this.project.id)
        },
        async _fetchWebhooksRecords() {
            await this.fetchWebhooksRecords(this.project.id)
        }
    }
}
</script>