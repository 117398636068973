<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group label="Nombre" label-for="name">
            <b-form-input id="name"
              v-model="createForm.name"
              name="name"
              :state="nameErrors.length > 0 ? false:null"
              @input="$v.createForm.name.$touch()"/>
            <small class="text-danger">{{ nameErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="Nombre" label-for="name">
            <v-select
              v-model="createForm.event"
              label="name"
              placeholder="Selecciona un evento"
              :options="eventOptions"
              :reduce="eventOptions => eventOptions.name"
              @input="$v.createForm.event.$touch()">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ eventErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="URL" label-for="url">
            <b-form-input id="url"
              v-model="createForm.url"
              name="url"
              :state="urlErrors.length > 0 ? false:null"
              @input="$v.createForm.url.$touch()"/>
            <small class="text-danger">{{ urlErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="Descripcion" label-for="description">
            <b-form-textarea
              v-model="createForm.description"
              id="description"
              :state="descriptionErrors.length > 0 ? false:null"
              @input="$v.createForm.description.$touch()"
              rows="3"/>
            <small class="text-danger">{{ descriptionErrors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <div class="d-flex align-items-end justify-content-end mr-1 mt-1 mb-2">
          <div>
            <b-overlay
              :show="overlayForm"
              opacity="0.5"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
              @hidden="_onHidden()" >
              <b-button
                ref="button"
                :disabled="overlayForm"
                variant="primary"
                @click.prevent="_createWebhooks">
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"/>
                <span class="align-middle">
                  Guardar
                </span>
              </b-button>
            </b-overlay>
          </div>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  import {required, url} from 'vuelidate/lib/validators'
  import {mapActions} from 'vuex'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    props: ['project'],
    data(){
      return {
        overlayForm: false,
        eventOptions: [
          {name:'Norte'},
          {name:'Sur'},
          {name:'Este'},
          {name:'Oeste'},
        ],
        createForm: {
          project_id: '',
          name: '',
          event: '',
          url: '',
          description: ''
        }
      }
    },
    validations: {
      createForm: {
        name: {
          required,
        },
        event: {
          required,
        },
        url: {
          required,
          url
        },
        description: {
          required,
        }
      }
    },
    beforeMount(){
      this.createForm.project_id = this.project.id
    },
    computed: {
      nameErrors() {
        const errors = []
        if (!this.$v.createForm.name.$dirty) return errors
        !this.$v.createForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      eventErrors () {
        const errors = []
        if (!this.$v.createForm.event.$dirty) return errors
        !this.$v.createForm.event.required && errors.push('El evento es obligatorio')
        return errors
      },
      urlErrors() {
        const errors = []
        if (!this.$v.createForm.url.$dirty) return errors
        !this.$v.createForm.url.required && errors.push('La URL es obligatoria')
        !this.$v.createForm.url.url && errors.push('Debes ingresar una URL válida')
        return errors
      },
      descriptionErrors() {
        const errors = []
        if (!this.$v.createForm.description.$dirty) return errors
        !this.$v.createForm.description.required && errors.push('Campo obligatorio')
        return errors
      },
    },
    methods: {
      ...mapActions('projects',['createWebhooks']),
      async _createWebhooks () {
        this.$v.createForm.$touch()
        if (!this.$v.createForm.$invalid) {
          this.overlayForm = true
          this.createWebhooks(this.createForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'El webhook ha sido creado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
            this.createForm =  {
              name: '',
              event: '',
              url: '',
              description: '',
            }
            this.$v.createForm.$reset()
            this.$router.push({ name: 'projects'})
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No se ha podido crear el webhook',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    }
  }
</script>