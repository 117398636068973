<template>
  <div>
    <vs-row>
      <vs-col vs-w="12">
        <vs-card>
            <div class="borderDiv my-5">
                <div class="mx-1 px-5 mt-5">
                    <vs-row>
                        <vs-col>
                            <div class="align-center flex">
                                <vs-icon class="mr-5" icon="code" size="30px"></vs-icon>
                                <p class="text-lg font-medium">Registros de Webhooks</p>
                            </div>
                        </vs-col>
                    </vs-row>
                </div>
                <div class="mx-5 my-5">
                    <vs-table
                    :data="webhooksRecords"
                    noDataText="Datos no Disponibles">
                        <template slot="thead">
                            <vs-th>ID</vs-th>
                            <vs-th>Mensaje</vs-th>
                            <vs-th>Solicitud</vs-th>
                            <vs-th>Respuesta</vs-th>
                            <vs-th>Código estado</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(tr, index) in data">
                                <vs-td>
                                    {{tr.id}}
                                </vs-td>
                                <vs-td>
                                    {{tr.message}}
                                </vs-td>
                                <vs-td>
                                    {{tr.request_body}}
                                </vs-td>
                                <vs-td>
                                    {{tr.response_body}}
                                </vs-td>
                                <vs-td>
                                    {{tr.status_code}}
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'ProjectsWebhooksRecords',
        props: ['webhooksRecords'],
        data(){
            return {
                select:2,
                deleteWebhook: null,
                options:[
                    {text: 'Square', value: 1},
                ],
                webhooksTest:[
                    {name: 'Webhook test', url: 'www.firstwebhooks.cl', audits: [{id: 1, autor:"Autor", created_at:"22-10-20", event:"Evento", old_values:"Antiguo valor", new_values:"Nuevo valor"}]},
                ]
            }
        },
        methods:{
        }
    }
</script>