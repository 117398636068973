<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-table
            id="project-table"
            hover
            striped
            show-empty
            responsive="sm"
            class="position-relative mb-1"
            :items="webhooks"
            :fields="tableColumns"
            primary-key="id"
            empty-text="Datos no Disponibles">
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                <template #button-content>
                  <feather-icon size="16"
                    icon="MoreVerticalIcon"
                    class="align-middle text-body"/>
                </template>
                <b-dropdown-item @click.prevent="_auditWebhook(data.item)">
                  <feather-icon icon="BookmarkIcon"/>
                  <span class="align-middle ml-50">Auditorias</span>
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="_editWebhook(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="_deleteWebhook(data.item)">
                  <feather-icon icon="TrashIcon"  />
                  <span class="align-middle ml-50">Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <projects-webhooks-edit ref="modalWebhookEdit"></projects-webhooks-edit>
    </b-card>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import ProjectsWebhooksEdit from '@/views/projects/components/ProjectsWebhooksEdit'
  import ToastificationContent from '@core/components/toastification/ToastificationContent'

  export default {
    props: ['webhooks','project'],
    components: {ProjectsWebhooksEdit},
    data(){
      return {
        tableColumns: [
          { key: 'id', label: 'ID'},
          { key: 'description', sortable: false, label: 'Descripcion' },
          { key: 'url', sortable: false, label: 'URL' },
          { key: 'actions', label: 'Acciones' }
        ]
      }
    },
    methods:{
      ...mapActions('projects',['deleteWebhooks']),
      _auditWebhook(audits) {
        this.$router.push({ name: 'projects-webhooks-audits', params: { id: this.project.id, audits: audits }})
      },
      _editWebhook(webhook) {
        this.$refs.modalWebhookEdit._showModal(webhook)
      },
      _deleteWebhook(webhook) {
        this.$swal({
          title: '¿Estás seguro que deseas eliminar este webhook?',
          text: 'La siguiente acción eliminará el webhook seleccionado.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this._confirmDelete(webhook);
          }
        })
      },
      async _confirmDelete(webhook) {
        await this.deleteWebhooks(webhook)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Webhook eliminado con éxito',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'projects' })
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Problemas al eliminar el webhook',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
      },
    }
  }
</script>