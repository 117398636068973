<template>
  <div>
    <b-modal
      centered
      hide-footer
      ref="modalEdit"
      title="Estas editando un webhook ya creado">
      <b-form>
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group label="Nombre" label-for="name">
              <b-form-input id="name"
                v-model="editForm.name"
                name="name"
                :state="nameErrors.length > 0 ? false:null"
                @input="$v.editForm.name.$touch()"/>
              <small class="text-danger">{{ nameErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group label="Nombre" label-for="name">
              <v-select
                v-model="editForm.event"
                label="name"
                placeholder="Selecciona un evento"
                :options="eventOptions"
                :reduce="eventOptions => eventOptions.name"
                @input="$v.editForm.event.$touch()">
              <span slot="no-options">
                No se encontraron opciones
              </span>
              </v-select>
              <small class="text-danger">{{ eventErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group label="URL" label-for="url">
              <b-form-input id="url"
                v-model="editForm.url"
                name="url"
                :state="urlErrors.length > 0 ? false:null"
                @input="$v.editForm.url.$touch()"/>
              <small class="text-danger">{{ urlErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group label="Descripcion" label-for="description">
              <b-form-textarea
                v-model="editForm.description"
                id="description"
                :state="descriptionErrors.length > 0 ? false:null"
                @input="$v.editForm.description.$touch()"
                rows="3"/>
              <small class="text-danger">{{ descriptionErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <div class="d-flex align-items-end justify-content-end mr-1 mt-1">
            <b-form-group>
              <div>
                <b-overlay
                  :show="overlayForm"
                  opacity="0.5"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                  @hidden="_onHidden()">
                  <b-button block
                    ref="button"
                    :disabled="overlayForm"
                    variant="primary"
                    @click.prevent="_updateWebhooks()">
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Guardar
                    </span>
                  </b-button>
                </b-overlay>
              </div>
            </b-form-group>
          </div>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import {required, url} from 'vuelidate/lib/validators'
  import {mapActions} from 'vuex'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default {
    data(){
      return {
        overlayForm: false,
        eventOptions: [
          {name:'Norte'},
          {name:'Sur'},
          {name:'Este'},
          {name:'Oeste'},
        ],
        editForm: {
          id: '',
          name: '',
          event: '',
          url: '',
          description: ''
        }
      }
    },
    validations: {
      editForm: {
        name: {
          required,
        },
        event: {
          required,
        },
        url: {
          required,
          url
        },
        description: {
          required,
        }
      }
    },
    computed: {
      nameErrors() {
        const errors = []
        if (!this.$v.editForm.name.$dirty) return errors
        !this.$v.editForm.name.required && errors.push('El nombre es obligatorio')
        return errors
      },
      eventErrors () {
        const errors = []
        if (!this.$v.editForm.event.$dirty) return errors
        !this.$v.editForm.event.required && errors.push('El evento es obligatorio')
        return errors
      },
      urlErrors() {
        const errors = []
        if (!this.$v.editForm.url.$dirty) return errors
        !this.$v.editForm.url.required && errors.push('La URL es obligatoria')
        !this.$v.editForm.url.url && errors.push('Debes ingresar una URL válida')
        return errors
      },
      descriptionErrors() {
        const errors = []
        if (!this.$v.editForm.description.$dirty) return errors
        !this.$v.editForm.description.required && errors.push('Campo obligatorio')
        return errors
      },
    },
    methods: {
      ...mapActions('projects',['editWebhooks']),
      async _updateWebhooks () {
        this.$v.editForm.$touch()
        if (!this.$v.editForm.$invalid) {
          this.overlayForm = true
          this.editWebhooks(this.editForm)
          .then(res => {
            this._hideModal();
            this.editForm = {
              id: '',
              name: '',
              event: '',
              url: '',
              description: ''
            }
            this.$v.editForm.$reset()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Webhook modificado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al modificar el webhook',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }
      },
      _showModal(webhook) {
        this.editForm.id = webhook.id
        this.editForm.event = webhook.event_type
        this.editForm.name = webhook.name
        this.editForm.url = webhook.url
        this.editForm.description = webhook.description
        this.$refs['modalEdit'].show()
      },
      _hideModal() {
        this.$refs['modalEdit'].hide()
      },
      _onHidden() {
        this.$refs.button.focus()
      },
    },
  }
</script>